@import '../../styles/colors';

.footer {
    position: relative;
    bottom: 0;
    padding-bottom: 5px;
    a {
        color: $primary-color;
        &:hover {
            text-decoration: none;
            color: $secondary-color;
        }
    }
}