@import '../../styles/colors';

.nav {
    background-color: $secondary-color;
    padding: 8px
}

.nav:hover {
    background-color: $secondary-color;
}

.nav-link {
    color: $primary-color;
}

.nav-link:hover {
    color: $primary-color-20;
}

.nav-image {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 35px;
}