@import '../../../../../styles/colors';

.set-h2  {
    font-weight: 200;
}

.btn-input-next {
    &:hover {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(221, 181, 113, 0.4);
    }
    &:active {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }
}