@import '../../../../../styles/colors';

.text-todo-workout {
    margin-bottom: 0;
    font-size: 13px;
    margin-top: 5px;
}

.headline-todo-workout {
    padding-top: 5px;
    font-weight: 200;
    margin-bottom: 0;
}

.list-sets-bottom {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(179, 179, 179, 0.1);
    margin-top: 5px;
    border-bottom: solid 3px #ddb56e !important;
    color: #585858;
    p {
        font-size: 12px;
        font-weight: 400;
    }
}

.set-single-row {
    margin-bottom: 10px;
}

.headline-sets {
    padding-bottom: 5px;
}

.sets-name {
    font-size: 13px;
    color: $black;
    font-weight: 400;
}

.btn-index-workout {
    background-color: $white;
    border: 0;
    color: #494949;
}

.biw-first {
    border-left: solid 1px $primary-color;
    border-bottom: solid 1px $primary-color;
    -webkit-box-shadow: -1px 1px 3px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -1px 1px 3px -2px rgba(0, 0, 0, 0.5);
    box-shadow: -1px 1px 3px -2px rgba(0, 0, 0, 0.5);
}

.biw-m {
    border-bottom: solid 1px $primary-color;
    -webkit-box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
}

.biw-last {
    border-bottom: solid 1px $primary-color;
    border-right: solid 1px $primary-color;
    -webkit-box-shadow: 1px 1px 3px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 3px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 3px -2px rgba(0, 0, 0, 0.5);
}
