.icon-logout {
    color: #6c0000;
    font-size: 55px;
    margin-bottom: 15px;
    transform: scale(1);
    animation: logout 0.8s 1;
}

@keyframes logout {
    0% {
        transform: scale(0.8);
    }

    70% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}
