@import '../../styles/colors';

.profile-label {
    color: #414141 !important;
    font-size: 12px !important;
    font-weight: 200 !important;
}

.btn-profile-save {
    background-color: $primary_color !important;
}

.profile-error {
    font-size: 10px;
    color: #be0000;
}