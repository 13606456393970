@import "../../styles/form";
@import "../../styles/button";

body {
    height: 100%
}

.workout-login {
    height: 100%;
    background-image: url("/img/machbar-background.png");
    background-repeat: no-repeat;
    background-size: cover;
}