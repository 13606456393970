@import '../../../../../styles/colors';

.checkbox label::after,
.radio label::after {
    content: "";
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid $primary-color-50;
    border-radius: 0;
    min-width: 1.3em;
    min-height: 1.3em;
    height: 1.3em;
    width: 1.3em;
    float: left;
    margin-right: 1em;
    margin-top: 2px;
}

.radio .cr {
    border-radius: 0;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    color: $primary-color-50;
    position: absolute;
    font-size: 0.95em;
    line-height: 0;
    top: 10%;
    left: 20%;
}

.checkbox .form-check-label {
    display: flex;
    align-items: flex-star;
    align-content: flex-start;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: 0.5;
}

.check-icon-next {
    font-size: 45px;
    margin-top: 15px;
    animation: showNext 0.5s 1;
}

@keyframes showNext {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

@media (max-width: 576px) {
    .con-top-info {
        p {
            font-size: 12px;
        }
    }
}

.back-btn-list {
    color: #ddb56e;
    padding-left: 15px;
    padding-top: 10px;
}

.center-container-nextAction div {
    width: 245px;
    margin-top: 5px;
    font-weight: 100;
}