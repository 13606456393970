@import "~bootstrap/scss/bootstrap";

html {
  height: 100%;
}

#root {
  height: 100%;
  #router {
    height: 100%; 
  }
}
