@import '../../styles/colors';

.dashboard-img {
    background-image: url('/img/workout-dashboard.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
}

.headline-intro-kontakt {
    border-top: solid 2px #ddb56e;
    padding-top: 5px;
    margin-top: 10px;
    font-weight: 200;
    small {
        font-size: 14px;
        padding-left: 10px;
    }
}

.text-intro {
    font-weight: 200;
    font-size: 13px;
}
