@import '../../../../../styles/colors';

.btn-next-skip  {
    background-color: $white;
    color: $black;
    &:hover {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(221, 181, 113, 0.4);
    }
    &:active {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }
}