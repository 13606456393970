.set-timer {
    color: #ddb56e;
    font-size: 6em;
    height: 85px;
    padding: 10px;
    background-color: #000; 
    h3 {
        color: #FFF;
        padding-left: 80px;
        margin-bottom: 0;
        line-height: 40px;
    }
    h6 {
        line-height: 20px;
        color: #FFF;
        padding-left: 80px;
    }
}
