@import "./colors";


.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $white;
    background-color: #010101;
    background-clip: padding-box;
    border: 1px solid $primary-color;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    color: $white;
    background-color: #303030;
    border-color: $primary-color;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-group > label {
    color: $white;
    font-size: 18px;
}