@import '../../../styles/colors';

.step-training {
    background: $secondary-color;
    padding: 10px !important;
    color: #fff;
    a {
        text-decoration: none;
        color: #fff;
    }
    &:hover {
        .step-icon{
            color: $primary-color;
            cursor: pointer;
        }
    }
}

.step-training-text {
    font-weight: 400;
    font-size: 14px;
}

.step-icon {
    text-align: center;
    font-size: 60px;
    .fa-clipboard-list-check  {
        margin-top: -15px;
    }
}

.headline-intro {
    font-weight: 200;
    small {
        font-size: 14px;
        padding-left: 10px;
    }
}
