$primary-color-100: #ddb56e;
$primary-color-50: #dbc296;
$primary-color-20: #cfc5b2;

$secondary-color-100: #151515;

$third-color-100: #f38f00;

$grey-color-100: #181819;


$primary-color: $primary-color-100;
$secondary-color: $secondary-color-100;
$grey-color: $grey-color-100;
$white: #ffffff;
$black: #000000;

$status-color-open: #a0a1a3;
$status-color-done: #13c189;
$status-color-inprogress: #00a6e1;
$status-color-skipped: #9499c2;
$status-color-warning: #ffcb00;
$status-color-danger: #e5174e;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color
);

