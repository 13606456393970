@import '../../../../../../styles/colors';


.no-wifi-text {
    padding-top: 15px;
    .fa-wifi-slash {
        font-size: 50px;
        padding-bottom: 15px;
    }
    p {
        font-weight: 200;
    }
}


.btn-next-set {
    &:hover {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(221, 181, 113, 0.4);
    }
    &:active {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }

}