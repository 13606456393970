@import '../../../styles/colors';

.back-wrapper {
    background-color: #151515;
    h3 {
        padding-left: 15px;
        color: $white;
        font-weight: 200;
        font-size: 28px;
    }
    h5 {
        padding-left: 15px;
        color: $white;
        font-weight: 200;
        font-size: 18px;

    }
}

.datetime-main {
    margin-left: -10px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 100;
    font-size: 12px;
}

.exercis-head {
    background-color: #000000;
    color: #ffffff;
    height: 85px;
    padding: 15px;
    h5 {
        font-size: 18px;
        color: #d0d0d0;
    }
}

@media (max-width: 576px) {

    .worklfow-excecis-h3 {
        font-size: 22px;
        font-weight: 400;
    }
    .wsb-list-btn {
        right: 10px !important;
        bottom: 10px;
        padding: 10px 15px !important;
        font-size: 18px !important;
    }
}

.loading-container {
    width: 100%;
}