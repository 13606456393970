@import '../../../styles/colors';

.workout-container {
    background-color: #151515; 
    color: $white;
    .top {
        h5 {
            font-size: 17px;
            color: #818184;
            font-weight: 200;
        }
        h3 {
            font-size: 23px;
            font-weight: 400;
        }
    }
}

.workout-start-img {
    position: relative;
}

.workout-start-btn {
    background-color: $primary-color;
    color: $black;
    box-shadow: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    text-transform: uppercase;
    font-size: 22px !important;
    padding: 15px 20px !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    &:hover {
        background-color: $primary-color-50;
        border-color: $primary-color;
    }
}